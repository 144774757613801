.flush { padding: 0; }

$ratio1: 0.25rem; // 4px
$ratio2: 0.50rem; // 8px
$ratio3: 0.75rem; // 12px
$ratio4: 1.00rem; // 16px
$ratio5: 1.25rem; // 20px
$ratio6: 1.50rem; // 24px
$ratio7: 1.75rem; // 28px
$ratio8: 2.00rem; // 32px
$ratio9: 2.50rem; // 36px
$ratio10: 3.00rem; // 48px
$ratio11: 3.50rem; // 56px
$ratio12: 4.00rem; // 64px
$ratio13: 4.50rem; // 72px
$ratio14: 5.00rem; // 80px
$ratio15: 5.50rem; // 88px
$ratio16: 6.00rem; // 96px

$col1: calc(100vw * (1 / 4) / 12); // 1/4 col.
$col2: calc(100vw * (2 / 4) / 12); // 1/2 col.
$col3: calc(100vw * (3 / 4) / 12); // 3/4 col.
$col4: calc(100vw / 12); // 1 col.
$col5: calc(100vw * (5 / 4) / 12); // 1 1/4 col.
$col6: calc(100vw * (6 / 4) / 12); // 1 1/2 col.
$col7: calc(100vw * (7 / 4) / 12); // 1 3/4 col.
$col8: calc(100vw * 2 / 12); // 2 col.
$col9: calc(100vw * (9 / 4) / 12); // 2 1/4 col.
$col10: calc(100vw * (10 / 4) / 12); // 2 1/2 col.
$col11: calc(100vw * (11 / 4) / 12); // 2 3/4 col.
$col12: calc(100vw * 3 / 12); // 3 col.
$col13: calc(100vw * (13 / 4) / 12); // 3 1/4 col.
$col14: calc(100vw * (14 / 4) / 12); // 3 1/2 col.
$col15: calc(100vw * (15 / 4) / 12); // 3 3/4 col.
$col16: calc(100vw * 4 / 12); // 4 col.

$vh1: 0.5vh;
$vh2: 1vh;
$vh3: 1.5vh;
$vh4: 2vh;
$vh5: 4vh;
$vh6: 6vh;
$vh7: 10vh;
$vh8: 16vh;


// ******************
// WIDTH

.w100 { width: 100%;}
.w75 { width: 75%;}
.w66 { width: 66.6%;}
.w50 { width: 50%;}
.w33 { width: 33.3%;}
.w25 { width: 25%;}
.w0 { width: 0;}

.wc1 { width: $col4; }
.wc2 { width: $col8; }
.wc3 { width: $col12; }
.wc4 { width: $col16; }
.wc5 { width: calc(100vw * 5 / 12); }
.wc6 { width: calc(100vw * 6 / 12); }
.wc7 { width: calc(100vw * 7 / 12); }
.wc8 { width: calc(100vw * 8 / 12); }

// ******************
// MARGINS

.m0 { margin: 0;}
.m1 { margin: $ratio1; }
.m2 { margin: $ratio2; }
.m3 { margin: $ratio3; }
.m4 { margin: $ratio4; }
.m5 { margin: $ratio5; }
.m6 { margin: $ratio6; }
.m7 { margin: $ratio7; }
.m8 { margin: $ratio8; }
.m9 { margin: $ratio9; }
.m10 { margin: $ratio10; }
.m11 { margin: $ratio11; }
.m12 { margin: $ratio12; }
.m13 { margin: $ratio13; }
.m14 { margin: $ratio14; }
.m15 { margin: $ratio15; }
.m16 { margin: $ratio16; }
.mauto { margin: 0 auto; }

.mt0 { margin-top: 0; }
.mt1 { margin-top: $ratio1; }
.mt2 { margin-top: $ratio2; }
.mt3 { margin-top: $ratio3; }
.mt4 { margin-top: $ratio4; }
.mt5 { margin-top: $ratio5; }
.mt6 { margin-top: $ratio6; }
.mt7 { margin-top: $ratio7; }
.mt8 { margin-top: $ratio8; }
.mt9 { margin-top: $ratio9; }
.mt10 { margin-top: $ratio10; }
.mt11 { margin-top: $ratio11; }
.mt12 { margin-top: $ratio12; }
.mt13 { margin-top: $ratio13; }
.mt14 { margin-top: $ratio14; }
.mt15 { margin-top: $ratio15; }
.mt16 { margin-top: $ratio16; }

.mr0 { margin-right: 0; }
.mr1 { margin-right: $ratio1; }
.mr2 { margin-right: -0.7rem; }
.mr3 { margin-right: $ratio3; }
.mr4 { margin-right: $ratio4; }
.mr5 { margin-right: $ratio5; }
.mr6 { margin-right: $ratio6; }
.mr7 { margin-right: $ratio7; }
.mr8 { margin-right: $ratio8; }
.mr9 { margin-right: $ratio9; }
.mr10 { margin-right: $ratio10; }
.mr11 { margin-right: $ratio11; }
.mr12 { margin-right: $ratio12; }
.mr13 { margin-right: $ratio13; }
.mr14 { margin-right: $ratio14; }
.mr15 { margin-right: $ratio15; }
.mr16 { margin-right: $ratio16; }

.mb0 { margin-bottom: 0; }
.mb1 { margin-bottom: $ratio1; }
.mb2 { margin-bottom: $ratio2; }
.mb3 { margin-bottom: $ratio3; }
.mb4 { margin-bottom: $ratio4; }
.mb5 { margin-bottom: $ratio5; }
.mb6 { margin-bottom: $ratio6; }
.mb7 { margin-bottom: $ratio7; }
.mb8 { margin-bottom: $ratio8; }
.mb9 { margin-bottom: $ratio9; }
.mb10 { margin-bottom: $ratio10; }
.mb11 { margin-bottom: $ratio11; }
.mb12 { margin-bottom: $ratio12; }
.mb13 { margin-bottom: $ratio13; }
.mb14 { margin-bottom: $ratio14; }
.mb15 { margin-bottom: $ratio15; }
.mb16 { margin-bottom: $ratio16; }

.ml0 { margin-left: 0; }
.ml1 { margin-left: $ratio1; }
.ml2 { margin-left: $ratio2; }
.ml3 { margin-left: $ratio3; }
.ml4 { margin-left: $ratio4; }
.ml5 { margin-left: $ratio5; }
.ml6 { margin-left: $ratio6; }
.ml7 { margin-left: $ratio7; }
.ml8 { margin-left: $ratio8; }
.ml9 { margin-left: $ratio9; }
.ml10 { margin-left: $ratio10; }
.ml11 { margin-left: $ratio11; }
.ml12 { margin-left: $ratio12; }
.ml13 { margin-left: $ratio13; }
.ml14 { margin-left: $ratio14; }
.ml15 { margin-left: $ratio15; }
.ml16 { margin-left: $ratio16; }

.my0 { margin-top: 0; margin-bottom: 0; }
.my1 { margin-top: $ratio1; margin-bottom: $ratio1; }
.my2 { margin-top: $ratio2; margin-bottom: $ratio2; }
.my3 { margin-top: $ratio3; margin-bottom: $ratio3; }
.my4 { margin-top: $ratio4; margin-bottom: $ratio4; }
.my5 { margin-top: $ratio5; margin-bottom: $ratio5; }
.my6 { margin-top: $ratio6; margin-bottom: $ratio6; }
.my7 { margin-top: $ratio7; margin-bottom: $ratio7; }
.my8 { margin-top: $ratio8; margin-bottom: $ratio8; }
.my9 { margin-top: $ratio9; margin-bottom: $ratio9; }
.my10 { margin-top: $ratio10; margin-bottom: $ratio10; }
.my11 { margin-top: $ratio11; margin-bottom: $ratio11; }
.my12 { margin-top: $ratio12; margin-bottom: $ratio12; }
.my13 { margin-top: $ratio13; margin-bottom: $ratio13; }
.my14 { margin-top: $ratio14; margin-bottom: $ratio14; }
.my15 { margin-top: $ratio15; margin-bottom: $ratio15; }
.my16 { margin-top: $ratio16; margin-bottom: $ratio16; }

.mx0 { margin-left: 0; margin-right: 0; }
.mx1 { margin-left: $ratio1; margin-right: $ratio1; }
.mx2 { margin-left: $ratio2; margin-right: $ratio2; }
.mx3 { margin-left: $ratio3; margin-right: $ratio3; }
.mx4 { margin-left: $ratio4; margin-right: $ratio4; }
.mx5 { margin-left: $ratio5; margin-right: $ratio5; }
.mx6 { margin-left: $ratio6; margin-right: $ratio6; }
.mx7 { margin-left: $ratio7; margin-right: $ratio7; }
.mx8 { margin-left: $ratio8; margin-right: $ratio8; }
.mx9 { margin-left: $ratio9; margin-right: $ratio9; }
.mx10 { margin-left: $ratio10; margin-right: $ratio10; }
.mx11 { margin-left: $ratio11; margin-right: $ratio11; }
.mx12 { margin-left: $ratio12; margin-right: $ratio12; }
.mx13 { margin-left: $ratio13; margin-right: $ratio13; }
.mx14 { margin-left: $ratio14; margin-right: $ratio14; }
.mx15 { margin-left: $ratio15; margin-right: $ratio15; }
.mx16 { margin-left: $ratio16; margin-right: $ratio16; }

.mcr1 { margin-right: $col1; }
.mcr2 { margin-right: $col2; }
.mcr3 { margin-right: $col3; }
.mcr4 { margin-right: $col4; }
.mcr5 { margin-right: $col5; }
.mcr6 { margin-right: $col6; }
.mcr7 { margin-right: $col7; }
.mcr8 { margin-right: $col8; }
.mcr9 { margin-right: $col9; }
.mcr10 { margin-right: $col10; }
.mcr11 { margin-right: $col11; }
.mcr12 { margin-right: $col12; }
.mcr13 { margin-right: $col13; }
.mcr14 { margin-right: $col14; }
.mcr15 { margin-right: $col15; }
.mcr16 { margin-right: $col16; }

.mcl1 { margin-left: $col1; }
.mcl2 { margin-left: $col2; }
.mcl3 { margin-left: $col3; }
.mcl4 { margin-left: $col4; }
.mcl5 { margin-left: $col5; }
.mcl6 { margin-left: $col6; }
.mcl7 { margin-left: $col7; }
.mcl8 { margin-left: $col8; }
.mcl9 { margin-left: $col9; }
.mcl10 { margin-left: $col10; }
.mcl11 { margin-left: $col11; }
.mcl12 { margin-left: $col12; }
.mcl13 { margin-left: $col13; }
.mcl14 { margin-left: $col14; }
.mcl15 { margin-left: $col15; }
.mcl16 { margin-left: $col16; }

.mvt1 { margin-top: $vh1; }
.mvt2 { margin-top: $vh2; }
.mvt3 { margin-top: $vh3; }
.mvt4 { margin-top: $vh4; }
.mvt5 { margin-top: $vh5; }
.mvt6 { margin-top: $vh6; }
.mvt7 { margin-top: $vh7; }
.mvt8 { margin-top: $vh8; }

.mvb1 { margin-bottom: $vh1; }
.mvb2 { margin-bottom: $vh2; }
.mvb3 { margin-bottom: $vh3; }
.mvb4 { margin-bottom: $vh4; }
.mvb5 { margin-bottom: $vh5; }
.mvb6 { margin-bottom: $vh6; }
.mvb7 { margin-bottom: $vh7; }
.mvb8 { margin-bottom: $vh8; }

// ******************
// PADDING

.p0 { padding: 0; }
.p1 { padding: $ratio1; }
.p2 { padding: $ratio2; }
.p3 { padding: $ratio3; }
.p4 { padding: $ratio4; }
.p5 { padding: $ratio5; }
.p6 { padding: $ratio6; }
.p7 { padding: $ratio7; }
.p8 { padding: $ratio8; }
.p9 { padding: $ratio9; }
.p10 { padding: $ratio10; }
.p11 { padding: $ratio11; }
.p12 { padding: $ratio12; }
.p13 { padding: $ratio13; }
.p14 { padding: $ratio14; }
.p15 { padding: $ratio15; }
.p16 { padding: $ratio16; }

.pt0 { padding-top: 0; }
.pt1 { padding-top: $ratio1; }
.pt2 { padding-top: $ratio2; }
.pt3 { padding-top: $ratio3; }
.pt4 { padding-top: $ratio4; }
.pt5 { padding-top: $ratio5; }
.pt6 { padding-top: $ratio6; }
.pt7 { padding-top: $ratio7; }
.pt8 { padding-top: $ratio8; }
.pt9 { padding-top: $ratio9; }
.pt10 { padding-top: $ratio10; }
.pt11 { padding-top: $ratio11; }
.pt12 { padding-top: $ratio12; }
.pt13 { padding-top: $ratio13; }
.pt14 { padding-top: $ratio14; }
.pt15 { padding-top: $ratio15; }
.pt16 { padding-top: $ratio16; }

.pr0 { padding-right: 0; }
.pr1 { padding-right: $ratio1; }
.pr2 { padding-right: $ratio2; }
.pr3 { padding-right: $ratio3; }
.pr4 { padding-right: $ratio4; }
.pr5 { padding-right: $ratio5; }
.pr6 { padding-right: $ratio6; }
.pr7 { padding-right: $ratio7; }
.pr8 { padding-right: $ratio8; }
.pr9 { padding-right: $ratio9; }
.pr10 { padding-right: $ratio10; }
.pr11 { padding-right: $ratio11; }
.pr12 { padding-right: $ratio12; }
.pr13 { padding-right: $ratio13; }
.pr14 { padding-right: $ratio14; }
.pr15 { padding-right: $ratio15; }
.pr16 { padding-right: $ratio16; }

.pb0 { padding-bottom: 0; }
.pb1 { padding-bottom: $ratio1; }
.pb2 { padding-bottom: $ratio2; }
.pb3 { padding-bottom: $ratio3; }
.pb4 { padding-bottom: $ratio4; }
.pb5 { padding-bottom: $ratio5; }
.pb6 { padding-bottom: $ratio6; }
.pb7 { padding-bottom: $ratio7; }
.pb8 { padding-bottom: $ratio8; }
.pb9 { padding-bottom: $ratio9; }
.pb10 { padding-bottom: $ratio10; }
.pb11 { padding-bottom: $ratio11; }
.pb12 { padding-bottom: $ratio12; }
.pb13 { padding-bottom: $ratio13; }
.pb14 { padding-bottom: $ratio14; }
.pb15 { padding-bottom: $ratio15; }
.pb16 { padding-bottom: $ratio16; }

.pl0 { padding-left: 0; }
.pl1 { padding-left: $ratio1; }
.pl2 { padding-left: $ratio2; }
.pl3 { padding-left: $ratio3; }
.pl4 { padding-left: $ratio4; }
.pl5 { padding-left: $ratio5; }
.pl6 { padding-left: $ratio6; }
.pl7 { padding-left: $ratio7; }
.pl8 { padding-left: $ratio8; }
.pl9 { padding-left: $ratio9; }
.pl10 { padding-left: $ratio10; }
.pl11 { padding-left: $ratio11; }
.pl12 { padding-left: $ratio12; }
.pl13 { padding-left: $ratio13; }
.pl14 { padding-left: $ratio14; }
.pl15 { padding-left: $ratio15; }
.pl16 { padding-left: $ratio16; }

.py0 { padding-top: 0; padding-bottom: 0; }
.py1 { padding-top: $ratio1; padding-bottom: $ratio1; }
.py2 { padding-top: $ratio2; padding-bottom: $ratio2; }
.py3 { padding-top: $ratio3; padding-bottom: $ratio3; }
.py4 { padding-top: $ratio4; padding-bottom: $ratio4; }
.py5 { padding-top: $ratio5; padding-bottom: $ratio5; }
.py6 { padding-top: $ratio6; padding-bottom: $ratio6; }
.py7 { padding-top: $ratio7; padding-bottom: $ratio7; }
.py8 { padding-top: $ratio8; padding-bottom: $ratio8; }
.py9 { padding-top: $ratio9; padding-bottom: $ratio9; }
.py10 { padding-top: $ratio10; padding-bottom: $ratio10; }
.py11 { padding-top: $ratio11; padding-bottom: $ratio11; }
.py12 { padding-top: $ratio12; padding-bottom: $ratio12; }
.py13 { padding-top: $ratio13; padding-bottom: $ratio13; }
.py14 { padding-top: $ratio14; padding-bottom: $ratio14; }
.py15 { padding-top: $ratio15; padding-bottom: $ratio15; }
.py16 { padding-top: $ratio16; padding-bottom: $ratio16; }

.px0 { padding-left: 0; padding-right: 0; }
.px1 { padding-left: $ratio1; padding-right: $ratio1; }
.px2 { padding-left: $ratio2; padding-right: $ratio2; }
.px3 { padding-left: $ratio3; padding-right: $ratio3; }
.px4 { padding-left: $ratio4; padding-right: $ratio4; }
.px5 { padding-left: $ratio5; padding-right: $ratio5; }
.px6 { padding-left: $ratio6; padding-right: $ratio6; }
.px7 { padding-left: $ratio7; padding-right: $ratio7; }
.px8 { padding-left: $ratio8; padding-right: $ratio8; }
.px9 { padding-left: $ratio9; padding-right: $ratio9; }
.px10 { padding-left: $ratio10; padding-right: $ratio10; }
.px11 { padding-left: $ratio11; padding-right: $ratio11; }
.px12 { padding-left: $ratio12; padding-right: $ratio12; }
.px13 { padding-left: $ratio13; padding-right: $ratio13; }
.px14 { padding-left: $ratio14; padding-right: $ratio14; }
.px15 { padding-left: $ratio15; padding-right: $ratio15; }
.px16 { padding-left: $ratio16; padding-right: $ratio16; }

.pcr1 { padding-right: $col1; }
.pcr2 { padding-right: $col2; }
.pcr3 { padding-right: $col3; }
.pcr4 { padding-right: $col4; }
.pcr5 { padding-right: $col5; }
.pcr6 { padding-right: $col6; }
.pcr7 { padding-right: $col7; }
.pcr8 { padding-right: $col8; }
.pcr9 { padding-right: $col9; }
.pcr10 { padding-right: $col10; }
.pcr11 { padding-right: $col11; }
.pcr12 { padding-right: $col12; }
.pcr13 { padding-right: $col13; }
.pcr14 { padding-right: $col14; }
.pcr15 { padding-right: $col15; }
.pcr16 { padding-right: $col16; }

.pcl1 { padding-left: $col1; }
.pcl2 { padding-left: $col2; }
.pcl3 { padding-left: $col3; }
.pcl4 { padding-left: $col4; }
.pcl5 { padding-left: $col5; }
.pcl6 { padding-left: $col6; }
.pcl7 { padding-left: $col7; }
.pcl8 { padding-left: $col8; }
.pcl9 { padding-left: $col9; }
.pcl10 { padding-left: $col10; }
.pcl11 { padding-left: $col11; }
.pcl12 { padding-left: $col12; }
.pcl13 { padding-left: $col13; }
.pcl14 { padding-left: $col14; }
.pcl15 { padding-left: $col15; }
.pcl16 { padding-left: $col16; }

.pvt1 { padding-top: $vh1; }
.pvt2 { padding-top: $vh2; }
.pvt3 { padding-top: $vh3; }
.pvt4 { padding-top: $vh4; }
.pvt5 { padding-top: $vh5; }
.pvt6 { padding-top: $vh6; }
.pvt7 { padding-top: $vh7; }
.pvt8 { padding-top: $vh8; }

.pvb1 { padding-bottom: $vh1; }
.pvb2 { padding-bottom: $vh2; }
.pvb3 { padding-bottom: $vh3; }
.pvb4 { padding-bottom: $vh4; }
.pvb5 { padding-bottom: $vh5; }
.pvb6 { padding-bottom: $vh6; }
.pvb7 { padding-bottom: $vh7; }
.pvb8 { padding-bottom: $vh8; }