// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

/***** Menu ****/

nav.module-menu .wrapper.right .main-menu ul li a div {
    color: #000;
    font-weight: 400;
    font-size: 20px;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.phone a .icon .hoverable, nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.icon a .hoverable {
    filter: none !important;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.item:hover a div{
    color: #fff;
}
nav.module-menu .wrapper.right .main-menu ul li.phone a .phonenumber {
    margin-right: 10px;
}
nav.module-menu .wrapper.right .main-menu ul li.phone a.flex .phonenumber {
    color: #851221;
    font-family: "Fira Sans Condensed", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 25px;
}
nav.module-menu .wrapper.right .main-menu ul li.icon img.hoverable.iconHover, nav.module-menu .wrapper.right .main-menu ul li.phone img.hoverable.iconHover {
    display: none;
}
nav.module-menu .wrapper.right .main-menu ul li.icon:hover img.hoverable.iconHover, nav.module-menu .wrapper.right .main-menu ul li.phone:hover img.hoverable.iconHover {
    display: block;
}
nav.module-menu .wrapper.right .main-menu ul li.icon:hover img.hoverable, nav.module-menu .wrapper.right .main-menu ul li.phone:hover img.hoverable {
    display: none;
}
/***** Bannière *****/

a.ls-gui-element.ls-nav-prev, a.ls-gui-element.ls-nav-next {
    display: none;
}
section#hero .slideshow-wrap .caption.container .row.text .col-xs-12 h2 {
    font-size: 55px;
    color: #dfdfdf;
    font-weight: 800;
    text-shadow: 2px 2px 5px #000, -2px -2px 5px #000, 2px -2px 5px #000, -2px 2px 5px #000;
}

/***** bloc Texte *****/

#blocTexte {
    padding: 50px 0;
    text-align: center;
}

#blocTexte h3 {
    color: #851219;
    font-size: 1.3rem;
    margin-bottom: 20px;
}
#blocTexte p {
    font-weight: 500;
}

/***** bloc Burst *****/

.burst .text-container .border p {
    color: #fff;
    font-size: 1.3rem;
    width: 40%;
    margin: auto;
    text-align: center;
    line-height: 1.40;
}
.burst.burst-left, .burst.burst-center {
    border-right: 15px solid #fff;
}
.burst.burst-left {
    border-left: 15px solid #fff;
}
.burst.burst-right {
    border-right: 15px solid #fff;
}
/***** bloc Logo *****/

#blocLogo {
    padding: 35px 0 50px 0;
    text-align: center;
}
#blocLogo h3 {
    color: #000;
    font-weight: 500;
    margin-bottom: 35px;
}
#blocLogo .imgLogo {
    display: flex;
    justify-content: space-evenly;
}
/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/


/*My setting*/


div.container-fluid.row > div {
    width: 50%;
}

div.container-fluid.row {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

section.section-03 {
    margin-top: 15px;
}

section.section-03 div.left-side, section.section-03 div.right-side {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/***** mecanique-camions-lourds.php ****/

#blocTexte-mcl {
    padding: 45px 0px 20px 0px;
    text-align: center;
}

#blocTexte-mcl h3 {
    color: #851219;
    font-size: 1.3rem;
    margin-bottom: 0px;
}
#blocTexte-mcl p {
    font-weight: 500;
}

section.section-02 {
  margin-top: 0px;
  div.left-side,
  div.right-side {
    padding: 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.text-box {
      max-width: 80%;
    }
  }

  div.right-side {
    background-color: #FFF;
  }
  div.left-side {
    background-color: #FFF;
  }

  p {
    color: #000;
    font-size: $font-size-22;
    line-height: 1.5;
    font-weight: 400;
    text-align: justify;
    width: 70%;
  }
}

.margintext-left p {
    margin-left: 180px;
}
.margintext-right p {
    margin-right:273px;
}

.colorsection {
  background-color:#851219;
  color:#FFF;
 }

.img-mcl {    
    background: url('../images/mecanique_camions_section02_burst01.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;
}

.img-mcl:hover{
    background: url('../images/mecanique_camions_section02_burst01_hover.jpg'); 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;
}

.centrado-mcl{
  position: absolute;
  top: 50%;
  left: 50%;   
  color: #FFF;
  text-align:center;
  transform: translate(-50%, -50%);
  width: 80%;
}

.imgdroite-mcl {    
    background: url('../images/mecanique_camions_section02_burst02.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;  
}

.imgdroite-mcl:hover{
    background: url('../images/mecanique_camions_section02_burst02_hover.jpg'); 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;     
}


.text-box ul li {
    margin-right:273px;
}

section.section-03 {
  margin-top: 15px;

  div.left-side,

  div.right-side {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.text-box {
      max-width: 80%;
    }
  }

  ul {
    list-style: none;
    li {
      color: #FFF;
      font-weight: 400;
      font-size: 13px;
      line-height: 2;
      position: relative;
      text-transform: inherit;
      &::before {
        content: ' ';
        background-image: url('../images/_point-blanc.png');
        background-position: center;
        background-size: 100% 100%;
        height: 10px;
        width: 10px;
        position: absolute;
        left: -20px;
        top: 8px;
        @media screen and (max-width: $size-xs-max){
          top: 7px;
          left: -30px;
        }
      }
    }
  }
}


/***** End mecanique-camions-lourds.php *****/

/***** Service-routiers.php *****/

#blocTexte-sr {
    padding: 45px 0px 20px 0px;
    text-align: center;
}

#blocTexte-sr h3 {
    color: #851219;
    font-size: 1.3rem;
    margin-bottom: 0px;
}

#blocTexte-sr p {
    font-weight: 500;    
    width: 80%;
    margin-left: 116px;
    margin-top: 20px;
    text-align: justify;
}

.img-sr {    
    background: url('../images/services_routiers_section02_burst01.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;
}

.img-sr:hover{
    background: url('../images/services_routiers_section02_burst01_hover.jpg'); 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;
}

.centrado-sr{
  position: absolute;
  top: 50%;
  left: 50%;   
  color: #FFF;
  text-align:center;
  transform: translate(-50%, -50%);
  width: 80%;
}

.imgdroite-sr {    
    background: url('../images/services_routiers_section02_burst02.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;  
}

.imgdroite-sr:hover{
    background: url('../images/services_routiers_section02_burst02_hover.jpg'); 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding-bottom: 40%;     
}


section.section-03-sr {
  margin-top: 15px;

  div.left-side,

  div.right-side {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.text-box {
      max-width: 70%;
      margin-right: 143px;
    }
  }

  ul {
    list-style: none;
    li {
      color: #FFF;
      font-weight: 400;
      font-size: 13px;
      line-height: 2;
      position: relative;
      text-transform: inherit;
      &::before {
        content: ' ';
        background-image: url('../images/_point-blanc.png');
        background-position: center;
        background-size: 100% 100%;
        height: 10px;
        width: 10px;
        position: absolute;
        left: -20px;
        top: 8px;
        @media screen and (max-width: $size-xs-max){
          top: 7px;
          left: -30px;
        }
      }
    }
  }
}

section.section-03-sr {
  margin-top: 15px;

  div.left-side,

  div.right-side {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.textalign-sr{
      max-width: 80%;
      margin-left: 122px;
    }
  }
}

/***** End Service-routiers.php *****/

/***** Coordonnees.php *****/

.text-box-coord{
  height:70px;
  text-align:center;
  padding: 20px;
}

.text-box-coord h3{
  color: #FFF;
  font-size: 1.3rem;
}

.burst-coord {
    width: 40%;
}

.burst-coord ul li {
    color: #000;
    font-size: .8rem;
    margin: auto;
    text-align: left;
    line-height: 1.40;
    margin-left: 49%;
    text-transform: inherit;
}

.burst-coord > img {
 float: left;
 margin-top: 1%;
 margin-left: 44%;
 margin-right: 4%;
 width:3%;
}

.text-box-horaire{
  margin-left: 32%;  
}

.table-horaire {
    padding: 20px;
    font-size: .8rem;
}

.table-horaire th {
    color: #000;
    font-size: .8rem;
    padding: 0 0 5px;
}

.table-horaire td{
  color: #000;
  font-weight:400;
  padding:3px;
}


/***** End Coordonnees.php *****/

/***** emplois.php *****/

.margintext-left-emploi p {
    margin-left: 250px;
    margin-top: -26%;
    width: 60%;
    text-align: justify;
    font-weight: 400;
    font-size: 15px;
}

section.section-03-emploi {
  margin-top: 0px;

  div.left-side,

  div.right-side {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.text-box {
      max-width: 100%;
    }
  }

  ul {
    list-style: none;
    li {
      color: #000;
      font-weight: 400;
      font-size: 15px;
      line-height: 2;
      position: relative;
      text-transform: inherit;
      &::before {
        content: ' ';
        background-image: url('../images/_point-rouge.png');
        background-position: center;
        background-size: 100% 100%;
        height: 10px;
        width: 10px;
        position: absolute;
        left: -20px;
        top: 8px;
        @media screen and (max-width: $size-xs-max){
          top: 7px;
          left: -30px;
        }
      }
    }
  }
}

.text-box-emploi h3{
  color: #000;
  font-size: 1rem;
}

.margintext-right-emploi p {
    text-align: justify;
    font-weight: 400;
    font-size: 15px;
}

.mybtn {
  width: 10%;
  background-color: #CCC;
  color: #000;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  /*cursor: pointer;*/
  font-weight: 500;
}

.formtitre{
  margin-top:30px;
}

.lamargingrow{
  margin:0px;
  padding:0px;  
}

.lamarginbois{
 padding:0px 0px 0px 231px; 
}

.colorform{
  background-color:#851219;
  color:#FFF;
}

.widthbois{
  width:70%;
  color:#000;
  font-weight:500;
  font-size:15px;
}

.widthbois2{
  width:60%;
}

.btnform {
    background-color: #CCC;
    color: #fff;
    padding: 15px;
    font-size: 0.8rem;
    width: 100%;
}

.module-formulaire{
  display:none;
}

.btn-file-upload{
     width: 100%;
     position:relative;
     height: 60px;
 }

.btn-file-upload:after{
    content:  attr(value);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;    
    width: 99%;
    background: #000;
    color: white;
    text-align: center;
    font-size: 15px;
    line-height: 4;
 }

 .mybtn2 {
    width: 88%;
    background-color: #CCC;
    color: #000;
    border: none;
    font-size: 15px;
    line-height: 4;
    font-weight: 500;
}

/***** End emplois.php *****/

/*end my setting*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: 1500px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li.item > a {
    padding-left: 0;
}
}

@media screen and (max-width: 1400px) {

nav.module-menu .wrapper.right .main-menu ul li a div {
    font-size: 18px;
}
}

@media screen and (max-width: 1360px) {

nav.module-menu .wrapper.right .main-menu ul li a div {
    font-size: 17px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-desktop .wrapper.right .main-menu {
    height: 65%;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li {
    height: 0;
}
nav.module-menu .menu-desktop {
    height: 100px;
}
}

@media screen and (max-width: $size-md-max) {
   
.burst .text-container .border p {
    width: 100%;
} 
#menu-mobile li.phone a {
    color: #851219 !important;
    font-size: 1.25rem !important;
} 
}

@media screen and (max-width: $size-sm-max) {

  #blocTexte br {
      display: none;
  }
  .burst.burst-center, .burst.burst-right {
      border-right: 15px solid #fff;
      border-left: 15px solid #fff;
  }
  #blocLogo .imgLogo img {
      padding: 16px;
  }
  #blocLogo .imgLogo {
      display: block;
  }
  #blocLogo h3 {
    font-size: 1.5rem;
  }
}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}


@media (min-width: 320px) and (max-width: 576px) { 

  /***** @media for mecanique-camions-lourds.php ****************/
    
    div.container-fluid.row > div {
        width: 100%;
    }

    section.section-02 div.left-side > div.text-box, section.section-02 div.right-side > div.text-box {
    max-width: 86%;
    }

    .margintext-left p {
      margin-left: 10px;
    }

    section.section-02 p {
      width: 94%;
    }

    .col-sm-6{
      margin:10px;
    }

    .img-mcl {    
    background: url('../images/mecanique_camions_section02_burst01_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;
    }


    .imgdroite-mcl {    
    background: url('../images/mecanique_camions_section02_burst02_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;  
    }

    .margintext-right p {
      margin-left: 10px;
    }

    .centrado-mcl {
      width: 89%;
    }

    .text-box ul li {
      margin-right: 16px;
    }

    section.section-03 div.left-side, section.section-03 div.right-side {
    padding: 0px 0;
    }

    /***** Fin media for mecanique-camions-lourds.php ****************/

    /***** Inicio media services-routiers.php ****************/
    #blocTexte-sr p {
      font-weight: 500;
      width: 96%;
      margin-left: 5px;
      margin-top: 20px;
      text-align: justify;
    }

    .img-sr {    
      background: url('../images/services_routiers_section02_burst01_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;
    }

    .imgdroite-sr {    
      background: url('../images/services_routiers_section02_burst02_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;  
    }


    section.section-03-sr div.left-side, section.section-03-sr div.right-side {
      padding: 0px 0;
    }

    section.section-03-sr div.left-side > div.text-box, section.section-03-sr div.right-side > div.text-box {
    max-width: 70%;
    margin-right: 82px;
    }

    .text-box-sr ul li{
      margin-left:38px;
    }

    /***** Fin media services-routiers.php ****************/

    /***** Inicio media emplois.php ****************/
    .margintext-left-emploi p {
      margin-left: 63px;
      margin-top: 0%;
      width: 60%;
      text-align: justify;
      font-weight: 400;
      font-size: 15px;
    }

    .text-right {
      text-align: left;
      padding:10px;
    }

    .text-box-emploi {
      padding:10px 0px 0px 10px;
    }

    .margintext-right-emploi p {
        margin-top: -30px;
    }

    .mybtn {
        width: 35%;
        margin-top: 30px;
    }

    .lamarginbois {
      padding: 0px 0px 0px 15px;
    }

    .widthbois {
      width: 100%;
    }

    .btn-file-upload {
      width: 70%;
      height: 60px;
      margin:5px;
    }

    .mybtn2 {
      width: 73%;
      padding: 0px 0px 0px 0px;
    }

    /***** Fin media empois.php ****************/

    /***** Inicio media coordonnees.php ****************/
    
    .burst-coord {
      width: 100%;
    }

    .burst-coord > img {        
        margin-left: 16%;        
    }

    .burst-coord ul li {
    font-size: .8rem;
    line-height: 1.40;
    margin-left: 18%;
    text-transform: inherit;
    }

    .table-horaire th {
      padding: 10px 0 5px;
    }

    /***** Fin media coordonnees.php ****************/

} 
/* fin @media (min-width: 320px) and (max-width: 576px)*/









@media (min-width: 577px) and (max-width: 767px) { 

  /***** @media for mecanique-camions-lourds.php ****************/
    div.container-fluid.row > div {
        width: 100%;
    }

    section.section-02 div.left-side > div.text-box, section.section-02 div.right-side > div.text-box {
    max-width: 86%;
    }

    .margintext-left p {
      margin-left: 10px;
    }

    section.section-02 p {
      width: 94%;
    }

    .col-sm-6{
      margin:10px;
    }

    .img-mcl {    
    background: url('../images/mecanique_camions_section02_burst01_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;
    }

    .imgdroite-mcl {    
    background: url('../images/mecanique_camions_section02_burst02_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;  
    }

    .margintext-right p {
      margin-left: 10px;
    }

    .centrado-mcl {
      width: 89%;
    }

    .text-box ul li {
      margin-right: 0px;
    }

    .text-box-right{
      margin-top: -16px;
      margin-left: 25px;
    }

    section.section-03 div.left-side, section.section-03 div.right-side {
    padding: 5px 0;
    }

    /***** Fin media for mecanique-camions-lourds.php ****************/

    /***** Inicio media services-routiers.php ****************/
    #blocTexte-sr p {
      font-weight: 500;
      width: 96%;
      margin-left: 5px;
      margin-top: 20px;
      text-align: justify;
    }

    .img-sr {    
      background: url('../images/services_routiers_section02_burst01_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;
    }

    .imgdroite-sr {    
      background: url('../images/services_routiers_section02_burst02_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;  
    }


    section.section-03-sr div.left-side, section.section-03-sr div.right-side {
      padding: 0px 0;
    }

    section.section-03-sr div.left-side > div.text-box, section.section-03-sr div.right-side > div.text-box {
    max-width: 70%;
    margin-right: 82px;
    }

    .text-box-sr{
      margin-top: -10px;
      margin-left: 46px;
    }

    /***** Fin media services-routiers.php ****************/

    /***** Inicio media emplois.php ****************/
    .margintext-left-emploi p {
      margin-left: 24px;
      margin-top: 0%;
      width: 90%;
    }

    .textalign {
      text-align: left;
      margin-left: 36px;
    }

    .text-right {
      text-align: left;
      padding:10px;
    }

    .text-box-emploi {
      padding:10px 0px 0px 10px;
    }

    .margintext-right-emploi p {
        margin-top: -30px;
    }

    .mybtn {
        width: 35%;
        margin-top: 30px;
    }

    .lamarginbois {
      padding: 0px 0px 0px 15px;
    }

    .widthbois {
      width: 100%;
    }

    .btn-file-upload {
      width: 100%;
      height: 60px;
      margin:5px;
    }

    .mybtn2 {
      width: 97%;
    }

    .mybtnbois{
      padding: 10px 0px 0px 19px;
    }

    /***** Fin media empois.php ****************/

    /***** Inicio media coordonnees.php ****************/
    
    .burst-coord {
      width: 32.5%;
    }

    .burst-coord > img {        
        margin-left: 8%;        
    }

    .burst-coord ul li {
      margin-left: 5%;
    }

    .text-box-horaire {
      margin-left: 17%;
      margin-top: -9px;
    }

    .table-horaire th {
      padding: 10px 0 5px;
    }

    /***** Fin media coordonnees.php ****************/

} 
/* fin @media (min-width: 577px) and (max-width: 767px) */









@media (min-width: 768px) and (max-width: 990px) {
 
   /***** @media for mecanique-camions-lourds.php ****************/
    
    div.container-fluid.row > div {
        width: 100%;
    }

    section.section-02 div.left-side > div.text-box, section.section-02 div.right-side > div.text-box {
    max-width: 86%;
    }

    .margintext-left p {
      margin-left: 10px;
    }

    section.section-02 p {
      width: 94%;
    }

    .col-sm-6{
      margin:10px;
    }

    .img-mcl {    
    background: url('../images/mecanique_camions_section02_burst01_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;
    }

    .imgdroite-mcl {    
    background: url('../images/mecanique_camions_section02_burst02_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;  
    }

    .margintext-right p {
      margin-left: 10px;
    }

    .centrado-mcl {
      width: 89%;
    }

    .text-box ul li {
      margin-right: 0px;
    }

    .text-box-right{
      margin-top: -16px;
      margin-left: 25px;
    }

    section.section-03 div.left-side, section.section-03 div.right-side {
    padding: 5px 0;
    }

    /***** Fin media for mecanique-camions-lourds.php ****************/

    /***** Inicio media services-routiers.php ****************/
    #blocTexte-sr p {
      font-weight: 500;
      width: 96%;
      margin-left: 5px;
      margin-top: 20px;
      text-align: justify;
    }

    .img-sr {    
      background: url('../images/services_routiers_section02_burst01_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;
    }

    .imgdroite-sr {    
      background: url('../images/services_routiers_section02_burst02_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;  
    }


    section.section-03-sr div.left-side, section.section-03-sr div.right-side {
      padding: 0px 0;
    }

    section.section-03-sr div.left-side > div.text-box, section.section-03-sr div.right-side > div.text-box {
    max-width: 70%;
    margin-right: 82px;
    }

    .text-box-sr{
      margin-top: -10px;
      margin-left: 46px;
    }

    /***** Fin media services-routiers.php ****************/

    /***** Inicio media emplois.php ****************/
    .margintext-left-emploi p {
      margin-left: 24px;
      margin-top: 0%;
      width: 90%;
    }

    .textalign {
      text-align: left;
      margin-left: 36px;
    }

    .text-right {
      text-align: left;
      padding:10px;
    }

    .text-box-emploi {
      padding:10px 0px 0px 10px;
    }

    .margintext-right-emploi p {
        margin-top: 0px;
    }

    .mybtn {
        width: 35%;
        margin-top: 30px;
    }

    .lamarginbois {
      padding: 0px 0px 0px 15px;
    }

    .widthbois {
      width: 100%;
    }

    .btn-file-upload {
      width: 101%;
      height: 62px;
      margin: 10px;
    }

    .mybtn2 {
      width: 97%;
    }

    .mybtnbois{
      padding: 10px 0px 0px 19px;
    }

    /***** Fin media empois.php ****************/

    /***** Inicio media coordonnees.php ****************/
    
    .burst-coord {
      width: 32.5%;
    }

    .burst-coord > img {        
        margin-left: 8%;        
    }

    .burst-coord ul li {
      margin-left: 5%;
    }

    .text-box-horaire {
      margin-left: 17%;
      margin-top: -9px;
    }

    .table-horaire th {
      padding: 10px 0 5px;
    }

    /***** Fin media coordonnees.php ****************/

} /* Fin @media (min-width: 768px) and (max-width: 990px)*/

@media (min-width: 991px) and (max-width: 1299px) {

   /***** @media for mecanique-camions-lourds.php ****************/
    
    div.container-fluid.row > div {
        width: 100%;
    }

    section.section-02 div.left-side > div.text-box, section.section-02 div.right-side > div.text-box {
    max-width: 86%;
    }

    .margintext-left p {
      margin-left: 10px;
    }

    section.section-02 p {
      width: 94%;
    }

    .col-sm-6{
      margin:10px;
    }

    .img-mcl {    
    background: url('../images/mecanique_camions_section02_burst01_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;
    }

    .imgdroite-mcl {    
    background: url('../images/mecanique_camions_section02_burst02_m.jpg');
    padding-bottom: 95%;
    height:90%;
    min-width:80%;  
    }

    .margintext-right p {
      margin-left: 10px;
    }

    .centrado-mcl {
      width: 89%;
    }

    .text-box ul li {
      margin-right: 0px;
    }

    .text-box-right{
      margin-top: -16px;
      margin-left: 25px;
    }

    section.section-03 div.left-side, section.section-03 div.right-side {
    padding: 5px 0;
    }

    /***** Fin media for mecanique-camions-lourds.php ****************/

    /***** Inicio media services-routiers.php ****************/
    #blocTexte-sr p {
      font-weight: 500;
      width: 96%;
      margin-left: 5px;
      margin-top: 20px;
      text-align: justify;
    }

    .img-sr {    
      background: url('../images/services_routiers_section02_burst01_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;
    }

    .imgdroite-sr {    
      background: url('../images/services_routiers_section02_burst02_m.jpg');
      padding-bottom: 95%;
      height:90%;
      min-width:80%;  
    }


    section.section-03-sr div.left-side, section.section-03-sr div.right-side {
      padding: 0px 0;
    }

    section.section-03-sr div.left-side > div.text-box, section.section-03-sr div.right-side > div.text-box {
    max-width: 70%;
    margin-right: 82px;
    }

    .text-box-sr{
      margin-top: -10px;
      margin-left: 46px;
    }

    /***** Fin media services-routiers.php ****************/

    /***** Inicio media emplois.php ****************/
    .margintext-left-emploi p {
      margin-left: 24px;
      margin-top: 0%;
      width: 90%;
    }

    .textalign {
      text-align: left;
      margin-left: 36px;
    }

    .text-right {
      text-align: left;
      padding:10px;
    }

    .text-box-emploi {
      padding:10px 0px 0px 10px;
    }

    .margintext-right-emploi p {
        margin-top: 0px;
    }

    .mybtn {
        width: 35%;
        margin-top: 30px;
    }

    .lamarginbois {
      padding: 0px 0px 0px 15px;
    }

    .widthbois {
      width: 100%;
    }

    .btn-file-upload {
      width: 101%;
      height: 62px;
      margin: 10px;
    }

    .mybtn2 {
      width: 97%;
    }

    .mybtnbois{
      padding: 10px 0px 0px 19px;
    }

    /***** Fin media empois.php ****************/

    /***** Inicio media coordonnees.php ****************/
    
    .burst-coord {
      width: 32.5%;
    }

    .burst-coord > img {        
        margin-left: 8%;        
    }

    .burst-coord ul li {
      margin-left: 5%;
    }

    .text-box-horaire {
      margin-left: 17%;
      margin-top: -9px;
    }

    .table-horaire th {
      padding: 10px 0 5px;
    }

    /***** Fin media coordonnees.php ****************/
  
} /* Fin (min-width: 991px) and (max-width: 1299px) */